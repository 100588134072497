import styles from './Home.module.scss';
import  {ReactComponent as HomePin}  from '../../../HomePin.svg'

const Home = () => (
  <div className={styles.container} id='home'>
    <h1 className={styles.title}>Hi I'm Filip👋</h1>
    <div className={styles.place}><HomePin/>Barlinek, Poland</div>
    <p>A passionate Front-end React Developer</p>
  </div>
);

export default Home;