import styles from './Skills.module.scss';

const Skills = () => (
<div className={styles.wrapper}>
  <p>Tech Stack</p>
  <div className={styles.skills}>
    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" alt="javascript logo"  />
    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" alt="react logo"  />
    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" alt="html5 logo"  />
    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg" alt="css3 logo"  />
    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg" height="30" alt="bootstrap logo"  />
    <img src="https://skillicons.dev/icons?i=github" alt="github logo"  />
    <img src="https://skillicons.dev/icons?i=tailwind" alt="tailwindcss logo"  />
    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg" alt="sass logo"/>
  </div>
</div>
);

export default Skills;