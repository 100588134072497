import styles from './NavBar.module.scss';


const NavBar = () => (
  <div className={styles.navbar}>
    <div>Filip.dev</div>
    <ul className={styles.links}>
      <li><a href="#home">Home</a></li>
      <li><a href="#projects">Projects</a></li>
      <li><a href="#contact">Contact</a></li>
    </ul>
  </div>
);

export default NavBar;