import { useSelector } from "react-redux";
import ProjectSingle from "../../ProjectSingle/ProjectSingle";
import { getAllProjects } from "../../../redux/store";
import styles from './Projects.module.scss';
import { Container } from "react-bootstrap";

const Projects = () => {

  const projects = useSelector(state => getAllProjects(state))

  return (
    <div className={styles.wrapper}>
      <Container>
        
          <div id="projects">
            <h2>My projects</h2>
          </div>
          <section>
            {projects.map((project, index) => (<ProjectSingle key={project.id} {...project} index={index} />))}
          </section>
      </Container>
    </div>
  )
};

export default Projects;
