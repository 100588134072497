import { Container } from "react-bootstrap";
import './global.scss';
import NavBar from "./components/Sections/NavBar/NavBar";
import Home from "./components/Sections/Home/Home";
import Skills from "./components/Sections/Skills/Skills";
import Projects from './components/Sections/Projects/Projects';
import ContactSection from "./components/Sections/Contact/Contact";

const App = () => {
  return (
    <div>
      <NavBar/>
      <Container fluid="md">
        <Home/>
        <Skills/>
        
      </Container>
      <Projects/>
      <ContactSection/>
    </div>
  );
}

export default App;
