import { ToDoListIMG } from "../Img/to-do-list";
import { WaiterAppIMG } from "../Img/Waiter-app-img";
import { ProjectPizzeriaIMG } from "../Img/Project-pizzeria";

const initialState = {
    projects: [
    {
      title: 'Waiter-app',
      description: 'A web app for waiters that simplifies table management. It displays tables from the database and lets users edit status and properties of each table',
      github: 'https://github.com/cosiek20/Waiter-App',
      link: 'https://waiter.filipwebdev.pl/',
      img: WaiterAppIMG,
      id: 'waiter-app',
      tech: [
        "react", "js", 'bootstrap'
      ]
    },
    {
      title: 'Pizzeria web page',
      description: 'Website for restaurant with about page. Products are generated from a database, users can customize them and place orders. In the Booking section users can book tables',
      github: 'https://github.com/cosiek20/Project-pizzeria',
      link: 'https://pizzeria.filipwebdev.pl',
      img: ProjectPizzeriaIMG,
      id: 'pizzeria-app',
      tech: [
        "js", "handlebars", "js classes"
      ]
    },
    {
      title: 'To-Do-List',
      description: 'Fully functional web app for task organization. Users can add lists, and edit cards and columns inside them. There is a favorite page where you can add the most important cards',
      github: 'https://github.com/cosiek20/To-do-list',
      link: 'https://to-do-list.filipwebdev.pl/',
      img: ToDoListIMG,
      id: 'to-do-list',
      tech: [
        "react", "js"
      ]
    },
  ]
};

export default initialState;